export function parseSearch() {
  let searchString = location.search.substring(1);
  searchString = decodeURI(searchString);
  const keyValues = searchString.split('&');

  const toReturn = {};

  for (let i = 0, len = keyValues.length; i < len; ++i) {
    const splitted = keyValues[i].split('=');
    toReturn[splitted[0]] = splitted[1];
  }

  return toReturn;
}
