export const TypeConstants = {
  DEFAULT_GUEST_AGE: null as number,
  EXCURSION_FARES: ['EBASIC', 'ECOSTA', 'WEBASIC', 'GEBASIC', 'WECOSTA', 'GECOSTA', 'SALLINCL', 'GTECOSTA', 'MNECOSTA', 'SALLINCL_CC'],
  ALLINCL_FARES: ['ALLINCL', 'WCOSTA', 'GCOSTA', 'GTCOSTA', 'MNCOSTA'],
  SALLINCL_FARES: ['SALLINCL', 'WECOSTA', 'GECOSTA', 'GTECOSTA', 'MNECOSTA'],
  TRAVELAGENT_FARE: 'TRAVELAG',
  GIFTCARD_VOUCHER: ['2-GCG01', '2-WVG02', '2-VAG03', '2-DT068', '2-DT098'],
  FUNNEL_QUICK_QUOTATION: 'qq',
  FUNNEL_CATEGORYSELECTION: 'gs',
  FUNNEL_CABINSELECTION: 'cs',
  FUNNEL_BOOKINGEDIT: 'be',
  FUNNEL_QUERYSTR: 'gf',
  FUNNEL_GLOB_VAR: 'funnelReferral',
  GROUP_FARE: 'GRP',
  NEWWEBCHECKIN_FLAG: 'WEBCHK',
  OLDWEBCHECKIN_FLAG: 'NOWEBCHK',
  INVOICE_OTHER_FIELDS: [
    'IVA / DNT',
    'Impuestos País',
    'Percepción RG 4815',
    '# Booking.lbl_Pratica_RPA',
    '# Booking.lbl_Pratica_PAI',
    '# Booking.lbl_Pratica_IVA',
  ],

  NETWORK_FARES: [
    'WBASIC',
    'WEBASIC',
    'GBASIC',
    'GEBASIC',
    'WCOSTA',
    'WECOSTA',
    'GCOSTA',
    'GECOSTA',
    'GTCOSTA',
    'GTECOSTA',
    'MNCOSTA',
    'MNECOSTA',
    'WEALLINC',
    'WELMYCRU',
    'WELALLIN',
    'WELEXPLO',
    'GEOMYCRU',
    'GEOALLIN',
    'GEOEXPLO',
    'MNMYCRU',
    'MNALLIN',
    'MNEXPLO',
    'GTNMYCRU',
    'GTNALLIN',
    'GTNEXPLO',
  ],
  ALL_INCL_CTA_DISCOUNT_CODE: ['#ALL#', '#DEL#'],
  NP2021_FARES: [
    'MYEXPLOR',
    'MYALLINC',
    'MYCRUISE',
    'WELMYCRU',
    'WELALLIN',
    'WELEXPLO',
    'GEOMYCRU',
    'GEOALLIN',
    'GEOEXPLO',
    'MNMYCRU',
    'MNALLIN',
    'MNEXPLO',
    'GTNMYCRU',
    'GTNALLIN',
    'GTNEXPLO',
  ],
  VAXRULES: ['1', '2', '3', '51', '52'],
  CCLUB_TIERS: ['Blue', 'Bronze', 'Silver', 'Gold', 'Platinum'], // non variare questo ordine
  CABIN_LOCATION_INTERNAL: 'Internal',
  CABIN_LOCATION_EXTERNAL: 'External',
  CABIN_LOCATION_BALCONY: 'Balcony',
  CABIN_LOCATION_SUITE: 'Suite',
  CABIN_LOCATION_TERRACE: 'Terrace',
};
