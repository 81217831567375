import { HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { CustomHeaders } from '@enums/CustomHeaders.enum';
import { TransformerResponseCodes } from '@enums/TransformerResponseCodes.enum';
import { KTResponse } from '@interfaces/Generic/KTResponse.interface';
import { BookingDataDTO } from '@interfaces/KT/BookingData.interface';
import { map } from 'rxjs';

export const GuestTBATransformer: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const TransformerCode = TransformerResponseCodes.GUEST_TBA;

  let headers = req.headers;
  const headerVal = headers.get(CustomHeaders.TRANSFORM_RESPONSE)?.split(',');

  // Se non include il codice del transform non fa nulla
  if (!headerVal || headerVal.indexOf(TransformerCode) < 0) return next(req);

  const newHeaderVal = headerVal.filter((val) => val !== TransformerCode);
  headers =
    newHeaderVal.length === 0
      ? headers.delete(CustomHeaders.TRANSFORM_RESPONSE)
      : headers.set(CustomHeaders.TRANSFORM_RESPONSE, newHeaderVal.join(','));

  return next(
    req.clone({
      headers,
    }),
  ).pipe(
    map((res) => {
      if (!(res instanceof HttpResponse)) return res;

      const body = (res as HttpResponse<KTResponse<BookingDataDTO>>).body;

      if (body?.d?.Result?.Cruises?.length) {
        body?.d?.Result?.Cruises?.forEach((cruise) => {
          cruise?.Cabins?.forEach((cabin) => {
            cabin?.Guests?.forEach((guest) => {
              guest.FirstName = guest.FirstName !== 'TBA' ? guest.FirstName : '';
              guest.LastName = guest.LastName !== 'TBA' ? guest.LastName : '';
            });
          });
        });
      }

      return (res as HttpResponse<KTResponse<BookingDataDTO>>).clone({
        body,
      });
    }),
  );
};
