import { PagePaths } from '@values/Navigation/PagePaths.value';
import Swal from '@global/config/defaultSwal';

import { redirectTo } from '../Navigation/redirectTo.utils';

export async function showUnprivilegedErrorMessage(title: string, text: string, confirmButtonText: string) {
  if (!Swal.isVisible()) {
    await Swal.fire({
      title,
      html: `
        <div style="font-size: 12px"> ${text} </div>
      `,
      confirmButtonText,
    }).then(() => {
      redirectTo(`${PagePaths.login}?fromSessionExpired=true`);
    });
  } else {
    console.debug('[HttpResponseInterceptor] Discarded Error: InvalidSession');
  }

  //Abilitare se vogliamo mettere il redirect post login all'ultima pagina visitata
  //&ref=${location.pathname}`;
}
