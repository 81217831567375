import { Directive, ElementRef, inject, Input } from '@angular/core';

@Directive({
  selector: 'div[cckBgImage], span[cckBgImage]',
  standalone: true,
})
export class BgImageDirective {
  private _el: ElementRef<HTMLDivElement> = inject(ElementRef);
  private _cckBgImage: string;
  public get cckBgImage(): string {
    return this._cckBgImage;
  }
  @Input() public set cckBgImage(cckBgImage: string) {
    this._cckBgImage = cckBgImage;
    if (this._cckBgImage?.length) this.setBgImage();
  }

  private setBgImage() {
    this._el.nativeElement.style.backgroundImage = `url('${this._cckBgImage}')`;
  }
}
