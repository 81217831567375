import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private queue: string[] = [];

  private _isLoading = false;
  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value) {
    this._isLoading = value;
    this.isLoading$.next(this._isLoading);
  }
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(this.isLoading);

  startLoading(key: string) {
    this.queue.push(key);
    this.isLoading = Boolean(this.queue.length);
    if (environment.showLoaderQueueInConsole) console.debug(`⌛(%c${this.queue.length.toString().padStart(3, '0')}%c) %cStartLoading%c: `, 'color:orange', '', 'color:green', '', (key ?? '').padEnd(75, ' '), this.queue);
  }

  stopLoading(key: string = null) {
    setTimeout(() => {
      this.queue.pop();
      this.isLoading = Boolean(this.queue.length);
      if (environment.showLoaderQueueInConsole) console.debug(`⌛(%c${this.queue.length.toString().padStart(3, '0')}%c) %cStopLoading%c:  `, 'color:orange', '', 'color:red', '', (key ?? '').padEnd(75, ' '), this.queue);
    }, 100);
  }
}
