import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { BgImageDirective } from '@directives/bg-image.directive';
import { PrependBasePathPipe } from '@pipes/prepend-base-path.pipe';
import { MarketService } from '@services/market.service';
import { pickRandomItem } from '@utils/Array/pickRandomItem.utils';

@Component({
  selector: 'cck-loader',
  standalone: true,
  imports: [CommonModule, PrependBasePathPipe, BgImageDirective],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  private readonly _marketService = inject(MarketService);

  public defaultImage = 'PublishingImages/banner/login/banner.png';
  public image = this.defaultImage;

  ngOnInit(): void {
    this.setRandomImage();
  }

  private setRandomImage() {
    if (!this._marketService.isLoginCarouselEnabled()) return;

    this.image = pickRandomItem(window.extconf.LoginCarousel ?? []) ?? this.defaultImage;
  }
}
