import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly TRAINING = 'training';
  private readonly PRE = 'live.cextrapre.it';
  private readonly TEST = 'live.cextratest.it';

  public isProd(): boolean {
    return !this.isLocal() && !this.isPre() && !this.isTest() && !this.isTraining();
  }

  public isPre(): boolean {
    return location.origin.indexOf(this.PRE) >= 0;
  }

  public isTraining(): boolean {
    return location.origin.indexOf(this.TRAINING) >= 0;
  }

  public isTest(): boolean {
    return location.origin.indexOf(this.TEST) >= 0;
  }

  public isLocal(): boolean {
    return location.origin.indexOf('oto') >= 0;
  }
}
