import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ActivatedRoute, provideRouter } from '@angular/router';
import { HttpResponseInterceptor } from '@interceptors/HttpResponse.interceptor';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EnvironmentService } from '@services/environment.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AddFiscodOnForeignGuestTransformer } from '@transformers/Requests/AddFiscodOnForeignGuest.transformer';
import { RemoveAutoSSVTransformer } from '@transformers/Requests/RemoveAutoSSV.transformer';
import { RemoveEmptyWebCheckinTransformer } from '@transformers/Requests/RemoveEmptyWebCheckin.transformer';
import { GuestTBATransformer } from '@transformers/Responses/GuestTBA.transformer';
import { RemoveFiscodOnForeignGuestTransformer } from '@transformers/Responses/RemoveFiscodOnForeignGuest.transformer';
import { basePathCurrentEnv } from '@utils/Navigation/basePathCurrentEnv.utils';
import { APP_ROUTES } from 'src/app/Root/app.routes';

import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { provideSwal } from './global/config/provideSwal';
import { loadLocale } from './global/config/locales';
import { setupDayjs } from './global/config/setupDayjs';
import './global/declarations';
import { parseSearch } from '@utils/Navigation/parseSearch.utils';
import { NGX_TRANSLATE_DEBUG_CONFIG, NgxTranslateDebugParser } from 'ngx-translate-debug';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

if (environment.production) {
  enableProdMode();
  // if (__webpack_public_path__) __webpack_public_path__ = '/Style Library/CCK/';
}

function CustomLoaderFactory(http: HttpBackend, _environmentService: EnvironmentService) {
  const prefix = _environmentService.isLocal()
    ? `https://www.costaextra.it${basePathCurrentEnv(false)}`
    : `${basePathCurrentEnv(false)}`;

  return new TranslateHttpLoader(new HttpClient(http), prefix, '.json');
}

const AppConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(APP_ROUTES),
    provideHttpClient(
      withInterceptors([
        HttpResponseInterceptor,
        RemoveEmptyWebCheckinTransformer,
        RemoveAutoSSVTransformer,
        AddFiscodOnForeignGuestTransformer,
        GuestTBATransformer,
        RemoveFiscodOnForeignGuestTransformer,
      ])
    ),
    {
      provide: LOCALE_ID,
      useValue: window.outputCulture
    },
    {
      provide: NGX_TRANSLATE_DEBUG_CONFIG,
      useValue: {
        localStorageKey: 'oto-ngx-translate-debug',
      },
    },
    importProvidersFrom(
      SweetAlert2Module.forRoot({
        provideSwal
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: CustomLoaderFactory,
          deps: [HttpBackend, EnvironmentService, ActivatedRoute],
        },
        parser: {
          provide: TranslateParser,
          useClass: NgxTranslateDebugParser,
        },
        defaultLanguage: 'LabelDispatcher',
      }),
    ),
    provideCharts(withDefaultRegisterables(
      DataLabelsPlugin
    )),
  ]
}

Promise.all([loadLocale(), setupDayjs()]).then(() => {
  bootstrapApplication(AppComponent, AppConfig).catch((err) => console.error(err))
})
