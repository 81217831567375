export const PagePaths = {
  forgotPassword: 'Pages/Reset-Password.aspx',
  request: 'Pages/Authorization-request.aspx',
  login: 'Pages/Login.aspx',
  step3: 'BookingAndCo/Booking/Pages/CategorySelection.aspx',
  step4: 'BookingAndCo/Booking/Pages/CabinSelection.aspx',
  step5: 'BookingAndCo/Booking/Pages/BookingQuote.aspx',
  bookingSummary: 'BookingAndCo/Booking/Pages/BookingSummary.aspx',
  bookingSummaryPrint: 'BookingAndCo/Booking/Pages/BookingSummaryPrint.aspx',
  bookingSummaryPrintJS: 'BookingAndCo/Booking/Pages/BookingSummaryPrintjs.aspx',
  bookingSummaryPrintNeti: 'BookingAndCo/Booking/Pages/BookingSummaryPrintNeti.aspx',
  bookingEdit: 'BookingAndCo/Booking/Pages/BookingEdit.aspx',
  result: 'BookingAndCo/Booking/Pages/Result.aspx',
  frontPage: 'Pages/FrontPage.aspx',
  frontPageTravelAgent: 'Pages/FrontPageTravelAgent.aspx',
  frontPageFramed: 'Pages/FrontPageFramed.aspx',
  searchBookings: 'BookingAndCo/Booking/BookingSearch/Pages/SearchResults.aspx',
  searchShorex: 'BookingAndCo/Escursioni/Pages/ShorexReservation.aspx',
  bookingCancellation: 'BookingAndCo/Booking/Pages/BookingCancellation.aspx',
  termsAndConditions: 'BookingAndCo/Booking/Pages/Terms.aspx',
  newsList: 'News/Pages/LatestNews.aspx',
  newsArticle: 'News/Pages/Article.aspx',
  shipInfo: 'Prodotto/Pages/Ships.aspx',
  shipInfoDetail: 'Prodotto/Pages/ShipInfo.aspx',
  cookieDetail: 'Pages/Cookies.aspx',
  Agos: 'BookingAndCo/Pages/Financing.aspx',
  AgosDocs: 'Documents/financing_docs_howto.pdf',
  resultGroup: 'BookingAndCo/Booking/GroupTool/Pages/GroupResult.aspx',
  viewGroup: 'BookingAndCo/Booking/GroupTool/Pages/GroupSummary.aspx',
  quoteGroup: 'BookingAndCo/Booking/GroupTool/Pages/GroupQuotation.aspx',
  groupTool: 'BookingAndCo/Booking/GroupTool/Pages/GroupTool.aspx',
  kpiUploader: 'CountryMng/Pages/GroupTool.aspx',
  report: 'BookingAndCo/Reportistica/Pages/Report.aspx',
  userPassword: 'LoggedOnArea/Pages/UserPassword.aspx',
  logout: '_Layouts/15/CostaClickNew/ServicePages/LogOut.aspx',
  BookingPaymentSuccess: 'BookingAndCo/Booking/Pages/PaymentSuccess.aspx',
  BookingPaymentFailure: 'BookingAndCo/Booking/Pages/PaymentFailure.aspx',
  BookingPaymentCancel: 'BookingAndCo/Booking/Pages/PaymentCancel.aspx',
  GroupPaymentSuccess: 'BookingAndCo/Booking/GroupTool/Pages/PaymentSuccess.aspx',
  GroupPaymentFailure: 'BookingAndCo/Booking/GroupTool/Pages/PaymentFailure.aspx',
  GroupPaymentCancel: 'BookingAndCo/Booking/GroupTool/Pages/PaymentCancel.aspx',
};
