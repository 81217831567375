import { inject, Injectable } from '@angular/core';
import { MSV } from '@enums/MSV.enum';
import { GroupToolDataDTO } from '@interfaces/KT/GroupToolData.interface';

import { EnvironmentService } from './environment.service';
import { getCurrentCulture } from '@utils/Navigation/getCurrentCulture.utils';
import { Market } from '@type/Generic/Market.type';
import { SessionStorageKey } from '@enums/SessionStorageKey.enum';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  private get marketCode() {
    return window.marketCode as Market;
  }
  private get cckBasePath() {
    return window.cckBasePath;
  }

  public readonly IT = 'it-IT';

  private _environmentService = inject(EnvironmentService);

  private readonly lookupVariable = this._environmentService.isProd() ? 'cckMsvPRO' : 'cckMsvPRE';

  public hasMyCostaBannerEnabled = () => true;
  public hasFiscalCodeEnabled = this.containsMarketWithAgency(MSV.FISCOD_ENABLED);
  public hasRecommendedEnabled = this.containsMarketWithAgency(MSV.RECOMMENDED);
  public hasGiftCardEnabled = this.containsMarketWithAgency(MSV.GIFTCARD);
  public hasCalendarioEventiEnabled = this.containsMarketWithAgency(MSV.EVENTS_CALENDAR);
  public hasWebCheckinBlockEnabled = this.containsMarketWithAgency(MSV.ENABLED_WEB_CHECK_IN);
  public isPreventivoEmozDisabledMarket = this.containsMarketWithAgency(MSV.EMOTIONAL_QUOTATION);
  public isNewRoomSelectorEnabledMarket = this.containsMarketWithAgency(MSV.NEW_ROOM_SELECTOR);
  public isRoomSelectorTwoEnabledMarket = this.containsMarketWithAgency(MSV.NEW_ROOM_SELECTOR_TWO);
  public isCostaClub2022EnabledMarkets = this.containsMarketWithAgency(MSV.CCLUB_2022);
  public hasNetiEnabled = this.containsMarketWithAgency(MSV.NETI);
  public hasLanguageSwitch = this.containsCulture(MSV.LANGUAGE_SWITCH);
  public hasNewDocumentSection = this.containsMarketWithAgency(MSV.NEW_DOCUMENT_SECTION);
  public hasCClubToggleEnabled = this.containsMarketWithAgency(MSV.CCLUB_TOGGLE);
  public hasAdditionalInfoEnabled = this.containsMarketWithAgency(MSV.ADDITIONAL_INFO);
  public hasDisableDepositDisabledMarkets = this.containsMarketWithAgency(MSV.DISABLE_DEPOSIT);
  public isEnabledCostaClubNoDefaultMarkets = this.containsMarketWithAgency(MSV.CCLUB_DEFAULT_NO);
  public isEnabledPaxNumDefaultMarkets = this.containsMarketWithAgency(MSV.PAX_NUM_DEFAULT);
  public isEnabledPaxAgesDefaultMarkets = this.containsMarketWithAgency(MSV.PAX_AGE_DEFAULT);
  public isEnabledTransportationInfoMarkets = this.containsMarketWithAgency(MSV.TRANSPORTATION_INFO);
  public isEnabledTransportationSelectionMarkets = this.containsMarketWithAgency(MSV.TRANSPORTATION_SELECTION);
  public isEnabledNationalitySelectionMarkets = this.containsMarketWithAgency(MSV.NATIONALITY_SELECTION);
  public isNewBookingSearchEnabled = this.containsMarketWithAgency(MSV.MYBOOKING_SEARCH);
  public isLoginCarouselEnabled = this.containsMarketWithAgency(MSV.LOGIN_CAROUSEL);
  public isNewSummaryPrintEnabled = this.containsMarketWithAgency(MSV.NEW_SUMMARY_PRINT);
  public isNewSummaryPrintEditingEnabled = this.containsMarketWithAgency(MSV.NEW_SUMMARY_PRINT_EDIT);
  public isAgencyDetailsExtendedEnabled = this.containsMarketWithAgency(MSV.AGENCY_DETAIL_EXTENDED);
  public hasGroupPaymentEnabled = this.containsMarketWithAgencyOrNetwork(MSV.GROUP_PAYMENT);
  public hasSeaDestination = this.containsMarketWithAgency(MSV.SEA_DESTINATION);
  public hasBookingPartialPaymentEnabled = this.containsMarketWithAgency(MSV.BOOKING_PARTIAL_PAYMENT);
  public hasUpsellSsvBanner = this.containsMarketWithAgency(MSV.UPSELL_SSV);
  public hasNewMyExplorLayout = this.containsMarketWithAgencyAndABTest(MSV.NEW_MYEXPLOR_LAYOUT);
  public hasWorldPayRedirect = this.containsMarketWithAgency(MSV.NEW_WORLD_PAY_REDIRECT);
  public hasGroupDepartedEnabled = this.containsMarketWithAgency(MSV.DEPARTED_GROUP);
  public hasHideGroupRateEnabled = this.containsMarketWithAgency(MSV.HIDE_GROUP_RATE);
  public hasCaliforniaLawEnabled = this.containsMarketWithAgency(MSV.CALIFORNIA_LAW);
  public hasUpsellFare = this.containsMarketWithAgencyAndABTest(MSV.UPSELL_FARE);
  public hasLoginConfirmAlert = this.containsMarketWithAgency(MSV.LOGIN_CONFIRM_ALERT);
  public hasGroupToolSearchEnabled = this.containsMarketWithAgencyOrNetwork(MSV.GROUP_TOOL_SEARCH);
  public hasFullPaymentsReceivedOverride = this.containsMarketWithAgency(MSV.FULLPAYMENTSRECEIVED_OVERRIDE);
  public hasNewUserPages = this.containsMarketWithAgency(MSV.NEW_USER_PAGE);
  public hasCheckUserEmailEnabled = this.containsMarketWithAgency(MSV.CHECK_USER_EMAIL);
  public hasPartialPaymentNetAmount = this.containsMarketWithAgency(MSV.PARTIAL_PAYMENT_NET);
  public hasBlackFridayEnabled = this.containsMarketWithAgency(MSV.BLACK_FRIDAY);

  private getMsvValue(key: MSV) {
    return window[this.lookupVariable][key];
  }

  private containsCulture(key: MSV): () => boolean {
    return () => {
      const data = this.getMsvValue(key);
      const culture = getCurrentCulture();
      return data?.hasOwnProperty(culture);
    };
  }

  private getNetworkCode(): string {
    let agencyData;
    try {
      agencyData = JSON.parse(sessionStorage.getItem(SessionStorageKey.AGENCY_DATA));
    } catch(e) {
      return "";
    }
    return agencyData?.Network?.trim().toUpperCase() || "";
  }

  private containsMarketWithAgencyOrNetwork(key: MSV): (agency?: string) => boolean {
    return (agency: string = null) => {
      agency ||= window.agencyCode;
      const network = this.getNetworkCode();

      const data = this.getMsvValue(key)
      if (!data) {
        console.warn("MSV: Can't get "+key);
        return false;
      }

      if (data.hasOwnProperty(this.marketCode)) {
        const marketConfig = data[this.marketCode];
        return marketConfig && (marketConfig.indexOf('*') >= 0 || marketConfig.indexOf(agency) >= 0 || marketConfig.indexOf(network) >= 0);
      } else if (
        (typeof data === 'object' && data.hasOwnProperty("*")) ||
        ( Array.isArray(data) && data.indexOf("*") >= 0)
      ) {
        return true;
      }
      return false;

    };
  }

  private containsMarketWithAgency(key: MSV): (agency?: string) => boolean {
    return (agency: string = null) => {
      agency ||= window.agencyCode;

      const data = this.getMsvValue(key)
      if (!data) {
        console.warn("MSV: Can't get "+key);
        return false;
      }

      if (data.hasOwnProperty(this.marketCode)) {
        const marketConfig = data[this.marketCode];
        return marketConfig && (marketConfig.indexOf('*') >= 0 || marketConfig.indexOf(agency) >= 0);
      } else if (
        (typeof data === 'object' && data.hasOwnProperty("*")) ||
        ( Array.isArray(data) && data.indexOf("*") >= 0)
      ) {
        return true;
      }
      return false;

    };
  }

  private containsMarketWithAgencyAndABTest(key: MSV): (agency?: string) => boolean {
    return (agency: string = null) => {
      agency ||= window.agencyCode;

      const data = this.getMsvValue(key)
      if (!data) {
        console.warn("MSV: Can't get "+key);
        return false;
      }

      const hasOptedOutABTest = localStorage.getItem(`AB_TEST_${key}`) === 'false';
      if (data.hasOwnProperty(this.marketCode)) {
        const marketConfig = data[this.marketCode];
        return marketConfig && (marketConfig.indexOf('*') >= 0 || (marketConfig.indexOf(agency) >= 0 && !hasOptedOutABTest));
      } else if (
        (typeof data === 'object' && data.hasOwnProperty("*")) ||
        ( Array.isArray(data) && data.indexOf("*") >= 0)
      ) {
        return true;
      }
      return false;

    };
  }

  public assignPricingIcon(imageUrl: string) {
    if (imageUrl?.indexOf('_new2021.') < 0) {
      const [imageName, imageExt] = imageUrl.split('.');
      return imageName + '_new2021.' + imageExt;
    }
    return imageUrl;
  }

  public getAgencyData(): GroupToolDataDTO {
    try {
      return JSON.parse(sessionStorage.getItem(SessionStorageKey.AGENCY_DATA));
    } catch (e) {
      console.warn('agency data corrupted', e);
      return {};
    }
  }

  public getDisabledFareByMarket() {
    const data = this.getMsvValue(MSV.DISABLED_FARES) as {
      m: string; // MARKET
      f: string[]; // FARES
      s: string[]; // STEPS
      e: string[]; // EXCLUDED MARKETS
    }[];

    const disabledStep: {
      [step: string]: string[];
    } = {};

    data?.forEach((config) => {
      const isCorrectMarket = config.m === this.marketCode;
      const isAllMarkets = config.m === '*';
      const isMarketExcluded = (config.e || []).indexOf(this.marketCode) >= 0;

      if (isCorrectMarket || (isAllMarkets && !isMarketExcluded)) {
        (config?.s || [])?.forEach((step) => {
          disabledStep[step] = [...(config?.f || [])];
        });
      }
    });

    return disabledStep;
  }

  public getWebCheckInAgencyBlockTooCloseDays(): number {
    const data = this.getMsvValue(MSV.DISABLED_WEB_CHECK_IN) as {
      m: string; // MARKET
      d: number; // DAYS
      e: string[]; // EXCLUDED MARKETS
    }[];
    let days = -1;

    data?.forEach((config) => {
      const isCorrectMarket = config.m === this.marketCode;
      const isAllMarkets = config.m === '*';
      const isMarketExcluded = (config.e || []).indexOf(this.marketCode) >= 0;

      if (isCorrectMarket || (isAllMarkets && !isMarketExcluded)) {
        if (config.d >= 0) days = config.d;
      }
    });

    return days;
  }

  public hasFinancingEnabled() {
    const isEnabled = this.containsMarketWithAgency(MSV.FINANCING);
    const agencydata = this.getAgencyData();
    const superConsortium = agencydata?.SuperConsortium || '';
    const toExclude = superConsortium.indexOf('I-OLT') >= 0;

    return isEnabled && !toExclude;
  }

  public hasShipAndShorex() {
    const data = this.getMsvValue(MSV.SHIP_AND_SHOREX) as {
      [marketCode: string]: string;
    };

    return data.hasOwnProperty(this.marketCode);
  }

  public getShipAndShorexPreselectedFilter() {
    const data = this.getMsvValue(MSV.SHIP_AND_SHOREX) as {
      [marketCode: string]: string;
    };

    return data[this.marketCode] || undefined;
  }

  public getLanguageSwitchLink(): {
    l: string;
    t: string;
  } {
    const data = this.getMsvValue(MSV.LANGUAGE_SWITCH) as {
      [culture: string]: {
        l: string; // Link new url
        t: string; // Text new url
      };
    };

    return data?.[getCurrentCulture()] || undefined;
  }
}
