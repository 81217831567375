import { registerLocaleData } from '@angular/common';
import deAT from '@angular/common/locales/de-AT';
import extraDeAT from '@angular/common/locales/extra/de-AT';
import de from '@angular/common/locales/de';
import extraDe from '@angular/common/locales/extra/de';
import enBZ from '@angular/common/locales/en-BZ';
import extraEnBZ from '@angular/common/locales/extra/en-BZ';
import enGB from '@angular/common/locales/en-GB';
import extraEnGB from '@angular/common/locales/extra/en-GB';
import enPH from '@angular/common/locales/en-PH';
import extraEnPH from '@angular/common/locales/extra/en-PH';
import en from '@angular/common/locales/en';
import extraEn from '@angular/common/locales/extra/en';
import esAR from '@angular/common/locales/es-AR';
import extraEsAR from '@angular/common/locales/extra/es-AR';
import es from '@angular/common/locales/es';
import extraEs from '@angular/common/locales/extra/es';
import esMX from '@angular/common/locales/es-MX';
import extraEsMX from '@angular/common/locales/extra/es-MX';
import frBE from '@angular/common/locales/fr-BE';
import extraFrBE from '@angular/common/locales/extra/fr-BE';
import fr from '@angular/common/locales/fr';
import extraFr from '@angular/common/locales/extra/fr';
import itCH from '@angular/common/locales/it-CH';
import extraItCH from '@angular/common/locales/extra/it-CH';
import it from '@angular/common/locales/it';
import extraIt from '@angular/common/locales/extra/it';
import nlBE from '@angular/common/locales/nl-BE';
import extraNlBE from '@angular/common/locales/extra/nl-BE';
import pt from '@angular/common/locales/pt';
import extraPt from '@angular/common/locales/extra/pt';
import ptPT from '@angular/common/locales/pt-PT';
import extraPtPT from '@angular/common/locales/extra/pt-PT';
import zhHans from '@angular/common/locales/zh-Hans';
import extraZhHans from '@angular/common/locales/extra/zh-Hans';

const angularLocales = new Map<string, { locale: any, extra: any }>([
  ['de-AT', { locale: deAT, extra: extraDeAT }],
  ['de-DE', { locale: de, extra: extraDe }],
  ['en-BZ', { locale: enBZ, extra: extraEnBZ }],
  ['en-GB', { locale: enGB, extra: extraEnGB }],
  ['en-PH', { locale: enPH, extra: extraEnPH }],
  ['en-US', { locale: en, extra: extraEn }],
  ['es-AR', { locale: esAR, extra: extraEsAR }],
  ['es-ES', { locale: es, extra: extraEs }],
  ['es-MX', { locale: esMX, extra: extraEsMX }],
  ['fr-BE', { locale: frBE, extra: extraFrBE }],
  ['fr-FR', { locale: fr, extra: extraFr }],
  ['it-CH', { locale: itCH, extra: extraItCH }],
  ['it-IT', { locale: it, extra: extraIt }],
  ['nl-BE', { locale: nlBE, extra: extraNlBE }],
  ['pt-BR', { locale: pt, extra: extraPt }],
  ['pt-PT', { locale: ptPT, extra: extraPtPT }],
  ['zh-CHS', { locale: zhHans, extra: extraZhHans }],
]);

export async function loadLocale() {
  const { locale, extra } = angularLocales.get(window.outputCulture ?? 'it-IT');
  registerLocaleData(locale, window.outputCulture, extra);

  return Promise.resolve();
}
