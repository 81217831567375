import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';

import deAT from 'dayjs/locale/de-at';
import deDE from 'dayjs/locale/de';
import enBZ from 'dayjs/locale/en';
import enGB from 'dayjs/locale/en-gb';
import enPH from 'dayjs/locale/en';
import enUS from 'dayjs/locale/en';
import esAR from 'dayjs/locale/es';
import esES from 'dayjs/locale/es';
import esMX from 'dayjs/locale/es-mx';
import frBE from 'dayjs/locale/fr';
import frFR from 'dayjs/locale/fr';
import itCH from 'dayjs/locale/it-ch';
import itIT from 'dayjs/locale/it';
import nlBE from 'dayjs/locale/nl-be';
import ptBR from 'dayjs/locale/pt-br';
import ptPT from 'dayjs/locale/pt';
import zhCHS from 'dayjs/locale/zh-cn';

const dayjsLocales = new Map<string, any>([
  ['de-AT', deAT],
  ['de-DE', deDE],
  ['en-BZ', enBZ],
  ['en-GB', enGB],
  ['en-PH', enPH],
  ['en-US', enUS],
  ['es-AR', esAR],
  ['es-ES', esES],
  ['es-MX', esMX],
  ['fr-BE', frBE],
  ['fr-FR', frFR],
  ['it-CH', itCH],
  ['it-IT', itIT],
  ['nl-BE', nlBE],
  ['pt-BR', ptBR],
  ['pt-PT', ptPT],
  ['zh-CHS', zhCHS],
]);

export async function setupDayjs() {
  return new Promise<void>((resolve) => {
    const localeKey = dayjsLocales.get(window.outputCulture);
    dayjs.locale(localeKey);
    dayjs.extend(localizedFormat);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(weekOfYear);
    dayjs.extend(weekday);
    dayjs.extend(isBetween);
    dayjs.extend(customParseFormat);
    dayjs.extend(minMax);
    dayjs.extend(localeData);
    dayjs.extend(updateLocale);
    if (localeKey.name === 'en') {
      dayjs.updateLocale('en', {
        formats: {
          L: 'MM/DD/YYYY',
          LL: 'MMMM D, YYYY',
          LLL: 'MMMM D, YYYY h:mm A',
          LLLL: 'dddd, MMMM D, YYYY h:mm A'
        }
      });
    }
    dayjs.extend(duration);
    // dayjs.Ls['en'].weekStart = 1;

    resolve();
  });
}

