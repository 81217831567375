export enum SessionStorageKey {
  AGENCY_DATA_EXT = 'agencyDataExt',
  AGENCY_DATA_FULL = 'agencyDataFull',
  AGENCY_DATA = 'agencyData',
  GROUP_LIST = 'groupList',
  OCCUPANCY_SELECTED = 'occupancySelected',
  USER_DATA = "userData",
  ISO_CODE = "IsoCode",
  BOOKING_LIST_BY_ADV = "BOOKING_LIST_BY_ADV",
  BOOKING_LIST_BY_ADV_OPT = "BOOKING_LIST_BY_ADV_OPT",
  BOOKING_LIST_BY_ADV_BKD = "BOOKING_LIST_BY_ADV_BKD",
  LOYALTY_PROGRAM = 'cckLoyaltyProgram',
  LOYALTY_PROGRAM_NUMBER = 'cckLoyaltyProgramNumber',
}
