import Swal from '@global/config/defaultSwal';

export async function showErrorMessage(title: string, text: string, confirmButtonText: string) {
  if (!Swal.isVisible()) {
    await Swal.fire({
      title,
      html: `
        <div style="font-size: 12px"> ${text} </div>
      `,
      confirmButtonText,
    });
  } else {
    console.debug('[HttpResponseInterceptor] Discarded Error: InvalidSession');
  }
}
