import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { HttpResponseStatus } from '@enums/HttpResponseStatus.enum';
import { TranslateService } from '@ngx-translate/core';
import { showErrorMessage } from '@utils/InterceptorAlerts/showErrorMessage.utils';
import { showPreconditionErrorMessage } from '@utils/InterceptorAlerts/showPreconditionErrorMessage.utils';
import { showUnprivilegedErrorMessage } from '@utils/InterceptorAlerts/showUnprivilegedErrorMessage.utils';
import { catchError, throwError } from 'rxjs';

export const HttpResponseInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const _translateService = inject(TranslateService);

  const title = _translateService.instant('Global.Error_Title');
  const textInvalidSession = `${_translateService.instant('Booking.Label_Sorry')} ${_translateService.instant(
    'Global.Error_InvalidSession',
  )}`;
  const textInvalidPrecondition = `${_translateService.instant('Booking.Label_Sorry')} ${_translateService.instant(
    'Global.Error_GenericError',
  )}`;
  const textGenericError = textInvalidPrecondition;
  const confirmButtonText = _translateService.instant('Global.btn_OK');

  return next(req).pipe(
    catchError((requestError: HttpErrorResponse) => {
      switch (requestError.status) {
        case HttpResponseStatus.UNAUTHORIZED:
          showUnprivilegedErrorMessage(title, textInvalidSession, confirmButtonText);
          break;
        case HttpResponseStatus.FORBIDDEN:
          showUnprivilegedErrorMessage(title, textInvalidSession, confirmButtonText);
          break;
        case HttpResponseStatus.PRECONDITION_FAILED:
          showPreconditionErrorMessage(title, textInvalidPrecondition, confirmButtonText);
          break;
        case HttpResponseStatus.PAYLOAD_TOO_LARGE:
          showErrorMessage(title, requestError.message, confirmButtonText);
          break;
        case HttpResponseStatus.INTERNAL_SERVER_ERROR:
          showErrorMessage(title, textGenericError, confirmButtonText);
          break;
      }

      return throwError(() => new Error(requestError.message));
    }),
  );
};
