<div class="loadingSpinner">
  <div class="loader" [cckBgImage]="image | prependBasePath">
    <div class="fading-circle">
      <div class="circle1 circle"></div>
      <div class="circle2 circle"></div>
      <div class="circle3 circle"></div>
      <div class="circle4 circle"></div>
      <div class="circle5 circle"></div>
      <div class="circle6 circle"></div>
      <div class="circle7 circle"></div>
      <div class="circle8 circle"></div>
      <div class="circle9 circle"></div>
      <div class="circle10 circle"></div>
      <div class="circle11 circle"></div>
      <div class="circle12 circle"></div>
    </div>
  </div>
</div>
