import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { CustomHeaders } from '@enums/CustomHeaders.enum';
import { TransformerRequestCodes } from '@enums/TransformerRequestCodes.enum';
import { BookingRequestBody } from '@interfaces/KT/BookingRequestBody.interface';

export const RemoveEmptyWebCheckinTransformer: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const TransformerCode = TransformerRequestCodes.REMOVE_EMPTY_WEB_CHECKIN;

  let headers = req.headers;
  const headerVal = headers.get(CustomHeaders.TRANSFORM_REQUEST)?.split(',');

  // Se non include il codice del transform non fa nulla
  if (!headerVal || headerVal.indexOf(TransformerCode) < 0) return next(req);

  const newHeaderVal = headerVal.filter((val) => val !== TransformerCode);
  headers =
    newHeaderVal.length === 0
      ? headers.delete(CustomHeaders.TRANSFORM_REQUEST)
      : headers.set(CustomHeaders.TRANSFORM_REQUEST, newHeaderVal.join(','));
  const body = req.body as BookingRequestBody;

  if (body?.request?.Booking?.Cruises?.length) {
    body?.request?.Booking?.Cruises?.forEach((cruise) => {
      cruise?.Cabins?.forEach((cabin) => {
        cabin?.Guests?.forEach((guest) => {
          if (!!guest.WebCheckIn && !!guest.WebCheckIn.EmergencyInfo) {
            const { EmergencyInfo } = guest.WebCheckIn;
            if (!EmergencyInfo.FirstName && !EmergencyInfo.LastName && !EmergencyInfo.TelephoneNumber) {
              guest.WebCheckIn = null;
            }
          }
        });
      });
    });
  }

  return next(
    req.clone({
      body,
      headers,
    }),
  );
};
