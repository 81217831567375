import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { CustomHeaders } from '@enums/CustomHeaders.enum';
import { TransformerRequestCodes } from '@enums/TransformerRequestCodes.enum';
import { BookingRequestBody } from '@interfaces/KT/BookingRequestBody.interface';
import { MarketService } from '@services/market.service';

export const AddFiscodOnForeignGuestTransformer: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const _marketService = inject(MarketService);
  const TransformerCode = TransformerRequestCodes.ADD_FISCOD_FOREIGN_GUEST;

  let headers = req.headers;
  const headerVal = headers.get(CustomHeaders.TRANSFORM_REQUEST)?.split(',');

  // Se non include il codice del transform non fa nulla
  if (!headerVal || headerVal.indexOf(TransformerCode) < 0) return next(req);

  const newHeaderVal = headerVal.filter((val) => val !== TransformerCode);
  headers =
    newHeaderVal.length === 0
      ? headers.delete(CustomHeaders.TRANSFORM_REQUEST)
      : headers.set(CustomHeaders.TRANSFORM_REQUEST, newHeaderVal.join(','));
  const body = req.body as BookingRequestBody;

  if (body?.request?.Booking?.Cruises?.length) {
    body?.request?.Booking?.Cruises?.forEach((cruise) => {
      cruise?.Cabins?.forEach((cabin) => {
        cabin?.Guests?.forEach((guest) => {
          if (guest.PrimaryContactInBooking && guest.zdisabledFiscalCodeForFiscalPax) {
            let hasFiscalCode = false;
            if (guest.Documents.length > 0) {
              guest.Documents?.forEach((doc) => {
                if (doc?.Type == 'FISCOD') {
                  hasFiscalCode = true;
                  doc.Number = 'FC_FOREIGN';
                }
              });
            }
            if (!hasFiscalCode && _marketService.hasFiscalCodeEnabled()) {
              guest.Documents.push({
                Type: 'FISCOD',
                Number: 'FC_FOREIGN',
              });
            }
          }
        });
      });
    });
  }

  return next(
    req.clone({
      body,
      headers,
    }),
  );
};
