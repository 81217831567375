import { Pipe, PipeTransform } from '@angular/core';
import { basePathCurrentEnv } from '@utils/Navigation/basePathCurrentEnv.utils';

@Pipe({
  name: 'prependBasePath',
  standalone: true,
})
export class PrependBasePathPipe implements PipeTransform {
  transform(path: string): string {
    if (!path?.length) return '';

    return `${basePathCurrentEnv()}${path}`;
  }
}
