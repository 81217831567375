import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EnvironmentInjector, inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from '@components/shared/loader/loader.component';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@services/loading.service';
import { parseSearch } from '@utils/Navigation/parseSearch.utils';
import { TypeConstants } from '@values/Booking/TypeConstants.value';
import { NgxTranslateDebugService } from 'ngx-translate-debug';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'cck-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterModule, LoaderComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  private _ngSelectConfig = inject(NgSelectConfig);
  private _loadingService = inject(LoadingService);
  private _translateDebugService = inject(NgxTranslateDebugService);
  private _changeDetectorRef = inject(ChangeDetectorRef);

  private loaderSub: Subscription;
  public isLoading: boolean;

  ngOnInit() {
    (window as any)[TypeConstants.FUNNEL_GLOB_VAR] = 'nd';
    if (parseSearch()['DisableTokenReplacement']) {
      this._translateDebugService.enableDebug();
    } else {
      this._translateDebugService.disableDebug();
    }

    this._ngSelectConfig.clearAllText = '';
    this._ngSelectConfig.notFoundText = '';
    this._ngSelectConfig.loadingText = '';
    this._ngSelectConfig.typeToSearchText = '';

    const form: HTMLFormElement = document.querySelector('#aspnetForm');
    if (form) form.onsubmit = (ev) => ev.preventDefault();
    if (window.parent) document.body.classList.add('bg-transparent');

    this.loaderSub = this._loadingService.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this._changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.loaderSub?.unsubscribe();
  }
}
