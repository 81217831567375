import { environment } from '@environments/environment';

export function basePathCurrentEnv(withOrigin = true): string {
  let basePath = window.cckBasePath;
  if (!environment.production) {
    const culturePositionInPathname = 2;
    basePath = withOrigin ? location.origin : '';
    basePath += '/CostaClick/' + location.pathname.split('/')[culturePositionInPathname] + '/';
  }

  return basePath;
}
