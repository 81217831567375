import { PagePaths } from '@values/Navigation/PagePaths.value';
import Swal from '@global/config/defaultSwal';

import { redirectTo } from '../Navigation/redirectTo.utils';

export async function showPreconditionErrorMessage(title: string, text: string, confirmButtonText: string) {
  if (!Swal.isVisible()) {
    await Swal.fire({
      title,
      html: `
        <div style="font-size: 12px"> ${text} </div>
      `,
      confirmButtonText,
    });
  } else {
    console.debug('[HttpResponseInterceptor] Discarded Error: InvalidSession');
  }

  redirectTo(PagePaths.frontPage);
}
