import SwalOrig from 'sweetalert2';

export default SwalOrig.mixin({
  title: '',
  icon: 'warning',
  iconHtml: `
    <img src="/Style%20Library/CostaClickNew/images/icons-swal/warning.svg" height="96" width="96"/>
  `,
  buttonsStyling: false,
  customClass: {
    title: 'h3 mb-2',
    htmlContainer: 'text-muted mx-3 my-0',
    confirmButton: 'btn btn-lg btn-primary mx-2 mw-btn',
    cancelButton: 'btn btn-lg btn-white mx-2 mw-btn',
    denyButton: 'btn btn-lg btn-white mx-2 mw-btn',
  },
  showClass: {
    popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
  },
  hideClass: {
    popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
  },
  showCloseButton: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false,
  reverseButtons: true,
});
