import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'CostaClick/it-IT',
    pathMatch: 'full',
  },
  {
    path: 'CostaClick/:culture',
    children: [
      {
        path: 'Pages',
        loadChildren: () => import('@root/Pages/pages.routes').then((m) => m.ROOT_PAGES_ROUTES),
      },
      {
        path: 'BookingAndCo',
        loadChildren: () => import('@root/BookingAndCo/booking-and-co.routes').then((m) => m.BOOKING_AND_CO_ROUTES),
      },
      {
        path: 'Help',
        loadChildren: () => import('@root/Help/help.routes').then((m) => m.HELP_ROUTES),
      },
      {
        path: 'MarketingTools',
        loadChildren: () => import('@root/MarketingTools/marketing-tools.routes').then((m) => m.MARKETING_TOOLS_ROUTES),
      },
      {
        path: 'Prodotto',
        loadChildren: () => import('@root/Prodotto/prodotto.routes').then((m) => m.PRODOTTO_ROUTES),
      },
      {
        path: 'LoggedOnArea',
        loadChildren: () => import('@root/LoggedOnArea/logged-on-area.routes').then((m) => m.LOGGED_ON_AREA_ROUTES),
      },
      {
        path: 'CountryMng',
        loadChildren: () => import('@root/CountryMng/country-mng.routes').then((m) => m.COUNTRY_MNG_ROUTES),
      },
      {
        path: 'Opportunita',
        loadChildren: () => import('@root/Opportunita/opportunita.routes').then((m) => m.OPPORTUNITA_ROUTES),
      },
      {
        path: '**',
        redirectTo: 'Pages/Login.aspx',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'CostaClick/it-IT/Pages/Login.aspx',
    pathMatch: 'full',
  },
];
